import React from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { useState, useEffect } from "react";

const Website = (props) => {
  const [balanceData, setBalanceData] = useState(0);

  const checkBalance = async () => {
    await axios
      .get(server + "/api/v1/website/balance", config)
      .then((res) => {
        setBalanceData(res.data.balance);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  useEffect(() => {
    checkBalance();
  }, []);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Website Settings
                    </h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">API Balance</h5>
                      </div>
                      <div className="card-body">
                        <div className="row p-2">
                          <div className="col">
                            {/* show current balance */}

                            <div className="row form-group">
                              <label className="form-label">
                                Current Balance
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={"$" + balanceData}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Website;
