import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
import Select from "react-select";

const BannedStates = (props) => {
  const [bannedStates, setBannedStates] = useState([]);
  const [bannedState, setBannedState] = useState({});
  const [loader, setLoader] = useState("");
  const [labelTypes, setLabelTypes] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [states, setStates] = useState([
    {
      ID: "AK",
      Name: "Alaska",
      Country: "US",
    },
    {
      ID: "AL",
      Country: "US",
      Name: "Alabama",
    },
    {
      ID: "AR",
      Country: "US",
      Name: "Arkansas",
    },
    {
      ID: "AZ",
      Country: "US",
      Name: "Arizona",
    },
    {
      ID: "CA",
      Country: "US",
      Name: "California",
    },
    {
      ID: "CO",
      Country: "US",
      Name: "Colorado",
    },
    {
      ID: "CT",
      Country: "US",
      Name: "Connecticut",
    },
    {
      ID: "DC",
      Country: "US",
      Name: "District Of Columbia",
    },
    {
      ID: "DE",
      Country: "US",
      Name: "Delaware",
    },
    {
      ID: "FL",
      Country: "US",
      Name: "Florida",
    },
    {
      ID: "GA",
      Country: "US",
      Name: "Georgia",
    },
    {
      ID: "HI",
      Country: "US",
      Name: "Hawaii",
    },
    {
      ID: "IA",
      Country: "US",
      Name: "Iowa",
    },
    {
      ID: "ID",
      Country: "US",
      Name: "Idaho",
    },
    {
      ID: "IL",
      Country: "US",
      Name: "Illinois",
    },
    {
      ID: "IN",
      Country: "US",
      Name: "Indiana",
    },
    {
      ID: "KS",
      Country: "US",
      Name: "Kansas",
    },
    {
      ID: "KY",
      Country: "US",
      Name: "Kentucky",
    },
    {
      ID: "LA",
      Country: "US",
      Name: "Louisiana",
    },
    {
      ID: "MA",
      Country: "US",
      Name: "Massachusetts",
    },
    {
      ID: "MD",
      Country: "US",
      Name: "Maryland",
    },
    {
      ID: "ME",
      Country: "US",
      Name: "Maine",
    },
    {
      ID: "MI",
      Name: "Michigan",
      Country: "US",
    },
    {
      ID: "MN",
      Country: "US",
      Name: "Minnesota",
    },
    {
      ID: "MO",
      Country: "US",
      Name: "Missouri",
    },
    {
      ID: "MS",
      Country: "US",
      Name: "Mississippi",
    },
    {
      ID: "MT",
      Country: "US",
      Name: "Montana",
    },
    {
      ID: "NC",
      Country: "US",
      Name: "North Carolina",
    },
    {
      ID: "ND",
      Country: "US",
      Name: "North Dakota",
    },
    {
      ID: "NE",
      Country: "US",
      Name: "Nebraska",
    },
    {
      ID: "NH",
      Country: "US",
      Name: "New Hampshire",
    },
    {
      ID: "NJ",
      Country: "US",
      Name: "New Jersey",
    },
    {
      ID: "NM",
      Country: "US",
      Name: "New Mexico",
    },
    {
      ID: "NV",
      Country: "US",
      Name: "Nevada",
    },
    {
      ID: "NY",
      Country: "US",
      Name: "New York",
    },
    {
      ID: "OH",
      Name: "Ohio",
      Country: "US",
    },
    {
      ID: "OK",
      Name: "Oklahoma",
      Country: "US",
    },
    {
      ID: "OR",
      Name: "Oregon",
      Country: "US",
    },
    {
      ID: "PA",
      Name: "Pennsylvania",
      Country: "US",
    },
    {
      ID: "PR",
      Country: "US",
      Name: "Puerto Rico",
    },
    {
      ID: "RI",
      Country: "US",
      Name: "Rhode Island",
    },
    {
      ID: "SC",
      Country: "US",
      Name: "South Carolina",
    },
    {
      ID: "SD",
      Country: "US",
      Name: "South Dakota",
    },
    {
      ID: "TN",
      Country: "US",
      Name: "Tennessee",
    },
    {
      ID: "TX",
      Country: "US",
      Name: "Texas",
    },
    {
      ID: "UT",
      Country: "US",
      Name: "Utah",
    },
    {
      ID: "VA",
      Country: "US",
      Name: "Virginia",
    },
    {
      ID: "VT",
      Country: "US",
      Name: "Vermont",
    },
    {
      ID: "WA",
      Country: "US",
      Name: "Washington",
    },
    {
      ID: "WI",
      Country: "US",
      Name: "Wisconsin",
    },
    {
      ID: "WV",
      Country: "US",
      Name: "West Virginia",
    },
    {
      ID: "WY",
      Country: "US",
      Name: "Wyoming",
    },

    // write canada provinces here
    {
      ID: "AB",
      Country: "CA",
      Name: "Alberta",
    },
    {
      ID: "BC",
      Country: "CA",
      Name: "British Columbia",
    },
    {
      ID: "MB",
      Country: "CA",
      Name: "Manitoba",
    },
    {
      ID: "NB",
      Country: "CA",
      Name: "New Brunswick",
    },
    {
      ID: "NL",
      Country: "CA",
      Name: "Newfoundland and Labrador",
    },
    {
      ID: "NS",
      Country: "CA",
      Name: "Nova Scotia",
    },
    {
      ID: "NT",
      Country: "CA",
      Name: "Northwest Territories",
    },
    {
      ID: "NU",
      Country: "CA",
      Name: "Nunavut",
    },
    {
      ID: "ON",
      Country: "CA",
      Name: "Ontario",
    },
    {
      ID: "PE",
      Country: "CA",
      Name: "Prince Edward Island",
    },
    {
      ID: "QC",
      Country: "CA",
      Name: "Quebec",
    },
    {
      ID: "SK",
      Country: "CA",
      Name: "Saskatchewan",
    },
    {
      ID: "YT",
      Country: "CA",
      Name: "Yukon",
    },
  ]);

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/bannedZips/labelTypes", config)
      .then((res) => {
        setLabelTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createBannedStates = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      labelType: e.target.labelType.value,
      template: e.target.template.value,
      states: selectedOption.map((option) => option.value),
    };

    await axios
      .post(server + "/api/v1/bannedStates/create", params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readBannedStates();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const readBannedStates = async () => {
    await axios
      .get(server + "/api/v1/bannedStates/read", config)
      .then((res) => {
        setBannedStates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateBannedStates = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      labelType: e.target.labelType.value,
      template: e.target.template.value,
      states: selectedOption.map((option) => option.value),
    };

    await axios
      .put(
        server + "/api/v1/bannedStates/update/" + bannedState._id,
        params,
        config
      )
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readBannedStates();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const deleteBannedState = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    await axios
      .delete(server + "/api/v1/bannedStates/delete/" + bannedState._id, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readBannedStates();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    readBannedStates();
    readLabelTypes();

    const options = states.map((state) => ({
      value: state.ID,
      label: state.Name,
    }));

    setOptions(options);
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "blue",
      borderRadius: 20,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-end mb-2">
                    <button
                      className="btn btn-sm btn-primary px-2 py-1"
                      data-target="#add"
                      data-toggle="modal"
                    >
                      Create
                    </button>
                  </div>
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">LabelType</th>
                        <th scope="col">Template</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bannedStates.map((stateData, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{stateData.labelType?.name}</td>
                          <td>{stateData.template}</td>

                          <td>
                            <button
                              className="btn btn-sm btn-info mr-2 px-2 py-1"
                              data-target="#update"
                              data-toggle="modal"
                              onClick={() => {
                                setBannedState(stateData);

                                const selectedStates = states.filter((state) =>
                                  stateData.states.includes(state.ID)
                                );

                                const selectedOptionsList = selectedStates.map(
                                  (state) => ({
                                    value: state.ID,
                                    label: state.Name,
                                  })
                                );

                                setSelectedOption(selectedOptionsList);
                              }}
                            >
                              <em className="icon ni ni-edit" />
                            </button>
                            <button
                              className="btn btn-sm btn-danger px-2 py-1"
                              data-target="#delete"
                              data-toggle="modal"
                              onClick={() => {
                                setBannedState(stateData);
                              }}
                            >
                              <em className="icon ni ni-trash" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create address modal */}
      <div
        id="add"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add LabelType
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={createBannedStates}>
                <div className="form-group mb-3">
                  <label htmlFor="name">LabelType</label>
                  <select
                    name="labelType"
                    id="labelType"
                    className="form-control"
                    required
                  >
                    <option value="">Select LabelType</option>
                    {labelTypes.map((labelType, index) => (
                      <option key={index} value={labelType._id}>
                        {labelType.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="name">template</label>
                  <select
                    name="template"
                    id="template"
                    className="form-control"
                    required
                  >
                    <option value="">Select template</option>
                    <option value="indicia">Endicia</option>
                    <option value="evs">EVS</option>
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="name">States</label>
                  <Select
                    isMulti={true}
                    defaultValue={selectedOption}
                    onChange={(e) => {
                      setSelectedOption(e);
                    }}
                    options={options}
                    id="multipleTagSelectInGroup"
                    placeholder="Select States"
                    closeMenuOnSelect={false}
                    styles={customStyles}
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Create {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* update bannedZip modal */}
      <div
        id="update"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={updateBannedStates}>
                <div className="form-group mb-3">
                  <label htmlFor="name">LabelType</label>
                  <select
                    name="labelType"
                    id="labelType"
                    className="form-control"
                    required
                    value={bannedState.labelType?._id}
                    onChange={(e) =>
                      setBannedState({
                        ...bannedState,
                        labelType: e.target.value,
                      })
                    }
                  >
                    <option value="">Select LabelType</option>
                    {labelTypes.map((labelType, index) => (
                      <option key={index} value={labelType._id}>
                        {labelType.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="name">template</label>
                  <select
                    name="template"
                    id="template"
                    className="form-control"
                    required
                    value={bannedState.template}
                    onChange={(e) =>
                      setBannedState({
                        ...bannedState,
                        template: e.target.value,
                      })
                    }
                  >
                    <option value="">Select template</option>
                    <option value="indica">Endicia</option>
                    <option value="evs">EVS</option>
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="name">States</label>
                  <Select
                    isMulti={true}
                    value={selectedOption}
                    onChange={(e) => {
                      console.log(e);
                      setSelectedOption(e);
                    }}
                    options={options}
                    id="multipleTagSelectInGroup"
                    placeholder="Select States"
                    closeMenuOnSelect={false}
                    styles={customStyles}
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-dark mr-3"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* delete bannedZip modal */}
      <div
        id="delete"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Delete
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <h5>Are you sure you want to delete this labelType?</h5>
              <div className="d-flex mt-2 justify-content-end ">
                <button
                  type="button"
                  className="btn btn-dark mr-3"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => deleteBannedState(e)}
                >
                  Delete {loader}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default BannedStates;
