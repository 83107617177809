import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

const BannedZips = (props) => {
  const [bannedZips, setBannedZips] = useState([]);
  const [bannedZip, setBannedZip] = useState({});
  const [loader, setLoader] = useState("");
  const [labelTypes, setLabelTypes] = useState([]);

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/bannedZips/labelTypes", config)
      .then((res) => {
        setLabelTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createBannedZips = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const zips = e.target.zips.value.split("\n");

    const params = {
      labelType: e.target.labelType.value,
      template: e.target.template.value,
      zips,
    };

    await axios
      .post(server + "/api/v1/bannedZips/create", params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const readBannedZips = async () => {
    await axios
      .get(server + "/api/v1/bannedZips/read", config)
      .then((res) => {
        setBannedZips(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateBannedZips = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const zips = e.target.zips.value.split("\n");

    const params = {
      labelType: e.target.labelType.value,
      template: e.target.template.value,
      zips,
    };

    await axios
      .put(
        server + "/api/v1/bannedZips/update/" + bannedZip._id,
        params,
        config
      )
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readBannedZips();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const deleteBanedZip = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    await axios
      .delete(server + "/api/v1/bannedZips/delete/" + bannedZip._id, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readBannedZips();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    readBannedZips();
    readLabelTypes();
  }, []);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex justify-content-end mb-2">
                    <button
                      className="btn btn-sm btn-primary px-2 py-1"
                      data-target="#add"
                      data-toggle="modal"
                    >
                      Create
                    </button>
                  </div>
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">LabelType</th>
                        <th scope="col">Template</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bannedZips.map((zip, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{zip.labelType?.name}</td>
                          <td>{zip.template}</td>

                          <td>
                            <button
                              className="btn btn-sm btn-info mr-2 px-2 py-1"
                              data-target="#update"
                              data-toggle="modal"
                              onClick={() => setBannedZip(zip)}
                            >
                              <em className="icon ni ni-edit" />
                            </button>
                            <button
                              className="btn btn-sm btn-danger px-2 py-1"
                              data-target="#delete"
                              data-toggle="modal"
                              onClick={() => setBannedZip(zip)}
                            >
                              <em className="icon ni ni-trash" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* create address modal */}
      <div
        id="add"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add LabelType
              </h5>
              <button type="button" data-dismiss="modal" aria-label="Close">
                {/* cross */}
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={createBannedZips}>
                <div className="form-group mb-3">
                  <label htmlFor="name">LabelType</label>
                  <select
                    name="labelType"
                    id="labelType"
                    className="form-control"
                    required
                  >
                    <option value="">Select LabelType</option>
                    {labelTypes.map((labelType, index) => (
                      <option key={index} value={labelType._id}>
                        {labelType.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="name">template</label>
                  <select
                    name="template"
                    id="template"
                    className="form-control"
                    required
                  >
                    <option value="">Select template</option>
                    <option value="indicia">Endicia</option>
                    <option value="evs">EVS</option>
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="name">ZIP Codes</label>
                  <textarea
                    name="zips"
                    id="zips"
                    className="form-control"
                    required
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Create {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* update bannedZip modal */}
      <div
        id="update"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form onSubmit={updateBannedZips}>
                <div className="form-group mb-3">
                  <label htmlFor="name">LabelType</label>
                  <select
                    name="labelType"
                    id="labelType"
                    className="form-control"
                    required
                    value={bannedZip.labelType?._id}
                    onChange={(e) =>
                      setBannedZip({
                        ...bannedZip,
                        labelType: e.target.value,
                      })
                    }
                  >
                    <option value="">Select LabelType</option>
                    {labelTypes.map((labelType, index) => (
                      <option key={index} value={labelType._id}>
                        {labelType.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="name">template</label>
                  <select
                    name="template"
                    id="template"
                    className="form-control"
                    required
                    value={bannedZip.template}
                    onChange={(e) =>
                      setBannedZip({ ...bannedZip, template: e.target.value })
                    }
                  >
                    <option value="">Select template</option>
                    <option value="indica">Endicia</option>
                    <option value="evs">EVS</option>
                  </select>
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="name">ZIP Codes</label>
                  <textarea
                    name="zips"
                    id="zips"
                    className="form-control"
                    required
                    value={bannedZip.zips?.join("\n")}
                    onChange={(e) =>
                      setBannedZip({
                        ...bannedZip,
                        zips: e.target.value.split("\n"),
                      })
                    }
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-dark mr-3"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* delete bannedZip modal */}
      <div
        id="delete"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Delete
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h5>Are you sure you want to delete this labelType?</h5>
              <div className="d-flex mt-2 justify-content-end ">
                <button
                  type="button"
                  className="btn btn-dark mr-3"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => deleteBanedZip(e)}
                >
                  Delete {loader}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default BannedZips;
